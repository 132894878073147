import dynamic from 'next/dynamic';
import {getIndividualPageData, getPageInfo, fetchAPIRequestData} from "@/api/global";
import {getFullListingInfo} from '@/api/listing';
const SlickComponentLibs = dynamic(() => import('@/components/CarouselComponentSlickStyles'));
const ListingNodeComponent = dynamic(() => import('@/components/04_templates/ListingPage/ListingPage'));
const GolfNodeComponent = dynamic(() => import('@/components/04_templates/CoursePage/CoursePage'));
const DefaultNodeComponent = dynamic(() => import('@/components/04_templates/DefaultNode'));

import ReactPlaceholder from 'react-placeholder';
import Typography from "@material-ui/core/Typography";

export async function getStaticProps(context) {
  const {params} = context;
  const page_path = params?.page?.join('/');
  if (page_path?.includes('sites/default/files')) {
    return {
      redirect: {
        destination: `https://cms.baywoodgreens.com/${page_path}`,
        statusCode: 307,
      },
    }
  }
  let initialProps = {
    statusCode: 200,
  };

  let data, node_data={}, list_media;
  // Get node's ID from the url.
  const page = Array.isArray(params?.page) ? params.page.join('/') : params?.page;

  try {
    if (page) {
      // Page data about currently opened path.
      data = await getPageInfo(`/${page}`);

      if (page_path?.includes('node/') && data.page && !(data.page?.resolved)?.includes(`/node/`)) {
        // "data.page.resolved" will be like "https://baywoodgreens.com/some-path-alias"
        let new_path = (data.page?.resolved).includes('.com') ? (data.page.resolved).split('.com/').pop() : (data.page.resolved).split('.site/').pop();
        return {
          redirect: {
            destination: `/${new_path}`,
            statusCode: 302,
          },
        }
      }

      // Check for any redirects:
      if (data?.page?.redirect?.length > 0) {
          return {
            redirect: {
              destination: data.page?.redirect[0]?.to,
              statusCode: parseInt(data.page?.redirect[0]?.status),
            },
          }
      }

      if (data?.page?.entity) {
        if (['house_listing'].includes(data.page.entity.bundle)) {
          node_data = await getFullListingInfo(data.page.entity.uuid, data.page.entity.bundle);
          if (node_data?.page_data?.homeNum?.length > 0) {
            list_media = await fetchAPIRequestData(`/jsonapi/rest/view/listing-media`, {sf_ref: node_data.page_data.homeNum}, {type: 'tld'});
          }
          if (node_data && list_media?.page_data) {
            node_data.page_data.slides = list_media.page_data;
          }
        }
        else if (['listing'].includes(data.page.entity.bundle)) {
          return {
            redirect: {
              destination: '/404',
              statusCode: 302,  // or 301 ?
            },
          }
        }
        else if (data.page.entity.bundle == 'article') {
          [recentNodes, node_data] = await innerRequests(data.page.jsonapi.individual);
        }
        else {
          node_data = await getIndividualPageData(data.page.jsonapi.individual);
        }

        if (node_data) {
          if (data?.page?.jsonapi && node_data.page_data && Object.keys(node_data.page_data).length < 1 && node_data?.statusCode == 500) {
            return {
              redirect: {
                destination: '/404',
                statusCode: 302,  // or 301 ?
              },
            }
          }
          else {
            initialProps = {...initialProps, ...node_data};
          }
        }
      }

      // Merge initially defined props with response from the backend.
      initialProps = {...initialProps, ...data};
    }

    if (!data) {
      return {
        notFound: true,
      }
    }
  }
  catch (e) {
    console.warn('Aliased page retrieval error:', e);
    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    initialProps.statusCode = 500;

    // In case of Server Side Rendering, we want the server to throw the
    // correct error code.
    // if (res) {
    //   res.statusCode = 500;
    // }
  }

  let returnProps = {
    props: {
      ...initialProps,
    },
    revalidate: 900,
    // notFound: true,

    // https://github.com/vercel/next.js/discussions/11862,
    // https://stackoverflow.com/questions/67946219/throw-new-errorfailed-to-load-static-props-when-setting-fallback-true-i/67946220#67946220
  };

  return returnProps;
}

export async function getStaticPaths() {
  const paths = [
    { params: { page: ['living'] } },
    { params: { page: ['living','homes'] } },
    { params: { page: ['living','homes','pre-owned'] } },
    { params: { page: ['living','homes','new'] } },
    { params: { page: ['living','homes','floorplans'] } },
    { params: { page: ['living','amenities'] } },
  ];
  // We'll pre-render only these paths at build time.
  // { fallback: false } means other routes should 404.
  return {paths, fallback: true}
}

function AliasedPage(props) {
  let node_data, node;

  if (props) {
    node = props?.page?.entity || null;
    if (node && props.page_data) {
      node_data = props.page_data;
    }
  }

  return (
    <div>
      <ReactPlaceholder type='text' rows={40} ready={node_data?.internalId} showLoadingAnimation={true}>
        {node && node_data &&
        <div className={`node node-type-${props?.page?.entity?.bundle} node-${props?.page?.entity?.id}`}>
          {(node_data && ['listing', 'house_listing'].includes(node.bundle)) &&
          <>
            <SlickComponentLibs/>
            <ListingNodeComponent node={node_data}/>
          </>
          }
          {(node_data && node.bundle == 'golf') &&
            <>
              <SlickComponentLibs/>
              <GolfNodeComponent node={node_data}/>
            </>
          }
          {(node_data && !['article', 'listing', 'house_listing', 'golf'].includes(node.bundle)) &&
          <>
            <SlickComponentLibs/>
            <DefaultNodeComponent node={node_data}/>
          </>
        }
        </div>
        }
        {(!node || !node_data) && props?.page?.label &&
        <>
          <Typography variant="h1" className={`page-title`}>{props.page.label}</Typography>
          <p>Hmmm... Looks like something is missing here. We will update it
            soon.</p>
        </>
        }
      </ReactPlaceholder>
    </div>
  );
}

export default AliasedPage;
